.Navbar {
  background-color: rgb(211, 28, 28);
  font-size: 20px;
  color: white;
  height: 70px;
}

.nav-link{
    color: red;
    border: 1px solid white;
    padding: 1em;
    text-decoration: none;
}


.add-form{
  background-color: beige;
  padding: 10px;
}