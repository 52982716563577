/* to use .css file, must place the code in index.js to override: */
/* <StyledEngineProvider injectFirst> */

/* don't use hyphen - for the name, must use underscore _ */
.text_red {    
  color: red;
}

.text_green {
  color: green;
}

